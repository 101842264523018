.stream {
  width: 130px;
  height: auto;
  max-width: 400px;
  min-height: 130px;
  border-radius: 50%;
  border: 4px solid rgba(84, 163, 176, 0.6);
  z-index: 3;
  position: relative;
}

.player span {
  position: absolute;
  z-index: 4;
  bottom: 0px;
  width: 100%;
  left: 0;
  cursor: pointer;
}

/* #agora_local {
  position: absolute;
  bottom: 2px;
  right: 3px;
  width: 130px;
  height: 130px;
} */
.player {
  border-radius: 50%;
}
.player div {
  border-radius: 50%;
}
.player video {
  position: relative !important;
  border-radius: 50%;
}

#agora_local {
  position: absolute;
  z-index: 2;
  width: 130px;
  height: 130px;
  top: 1%; 
  left: 2%;
  transform: scale(1.3);
}

#qr-code {
  position: absolute;
  z-index: 500;
  width: 130px;
  height: 130px;
  top: -20%; 
  left: 120%;
}

.pos1{
  top: 8.7%;
  left: 19%;
}

.pos2{
  top: 8.73%;
  right: 17.05%;
}

.pos3{
  top: 43.6%;
  right: 11.7%;
}

.pos5{
  top: 43.6%;
  left: 12.7%;
}

