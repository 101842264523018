.red{
    background: rgba(255, 0, 0, 0.712);
    margin: 0;
    color: #000;
    font-weight: 600;
    font-size: large;
}
.red a{
    color: rgb(2, 2, 2);
}
.wrap{
    background: white;
    position: relative;
    top:0;
    left: 0;
    right: 0;
}