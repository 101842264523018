@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

.table-requirements {
  display: block;
}

body {
  background: #0e1323;
  font-family: "Roboto", sans-serif;
  color: #fff;
  overflow-x: hidden;
}

.button {
  color: white;
  text-decoration: none;
  padding: .5rem 2rem;
  display: block;
  margin: 0 1rem;
  border-radius: 30px;
  cursor: pointer;
}

.button.btn-ghost {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.button.btn-ghost:hover {
  background: rgba(255, 255, 255, 0.1);
}

.button.btn-red {
  background: #c93c3c;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.button.btn-red:hover {
  background: #d15858;
}

.button ion-icon {
  position: relative;
  top: 2px;
  color: white;
  margin-right: .475rem;
}

.poker-header {
  position: absolute;
  width: 100%;
  z-index: 20;
  padding: 10px 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.poker-header .header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.poker-header .header-left .header-user {
  background: transparent;
  padding: 0;
  margin: 0 1rem;
}

.poker-header .header-left .header-user img {
  height: 32px;
  width: 32px;
  margin-right: 1rem;
}

.poker-header .header-left .header-user span {
  font-size: 1rem;
}

.poker-header .header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.poker-header .header-right .header-amount {
  background: transparent;
}

.poker-header .header-right .header-amount .amount-icon {
  width: 32px;
  height: 32px;
  margin: 0 1rem;
  left: 0;
}

.poker-header .header-right .header-amount span {
  font-size: 1rem;
}

@media (max-width: 900px) {
  .poker-header {
    width: 300px;
    height: 100%;
    padding: 2rem 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    background: -webkit-gradient(linear, left top, right top, color-stop(58%, #13192e), to(rgba(19, 25, 46, 0)));
    background: linear-gradient(to right, #13192e 58%, rgba(19, 25, 46, 0) 100%);
    z-index: 1050;
    padding-top: 4rem;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  }
  .poker-header > * {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .poker-header .header-left, .poker-header .header-right {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .poker-header .header-left > a, .poker-header .header-right > a {
    position: absolute;
    bottom: 10%;
  }
  .poker-header .header-left > a.btn-ghost, .poker-header .header-right > a.btn-ghost {
    bottom: calc(10% + 3.5rem);
  }
  .poker-header .header-left div, .poker-header .header-right div {
    margin: 2rem 0;
  }
  .poker-header .header-left .header-amount, .poker-header .header-right .header-amount {
    padding: 0;
    position: relative;
  }
  .poker-header .header-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .poker-header .roomdID {
    display: none;
  }
  .poker-header.is-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* .poker {
  width: 100vw;
  max-width: 1334px;
  height: 100vh;
  background: url("../../images/table-room.png") no-repeat center;
  background-size: cover;
  margin: 0 auto;
  position: relative;
} */

.header {
  display:flex;
  margin-top:20px;
  justify-content: space-between;
}

.header-user {
  float:left;
  padding:0 45px;
  background:#171c2e;
  border-radius:0 50px 50px 0;
  justify-content: center;
  display:flex;
  align-items: center;
}

.header-user img {
  width:60px;
  border-radius:50%;
  float:left;
  margin-right:30px;
  position:relative;
  transform: scale(1.3);
}

.header-user span, .header-amount span {
  font-size:18px;
  color:#fbdf6e;
  font-weight:800;
}

.header-amount {
  background:#171c2e;
  padding:0 45px 0 0;
  justify-content: center;
  display:flex;
  align-items: center;
  border-radius:50px 0 0 50px;
}

.header-amount span {
  margin-right:20px;
}

.header-amount > img {
  transform: scale(0.7);
  position:relative;
  left:-20px;
}

.lobby-table {
  width:30.3333%;
  float:left;
  align-items:center;
  padding: 1.5%;
  margin-top:30px;
}
.lobby-table:hover{
  transform: scale(1.07);
  cursor: pointer;
}

.link{
  text-decoration: none;
}

.lobby-sidebar {
  width:20%;
  float:left;
  margin-top:60px;
  align-items: center;
  justify-content: center;
}

.lobby-container {
  width:70%;
  float:left;
}
.lobby {
  display:flex;
  min-height:calc(100vh - 120px);
}
.lobby-buttons-row {
  display:block;
  width:100%;
  text-align:center;
}

/* modal form */
#create-room-form, #join-room-form {
  position: absolute;
  top:25%;
  left: 35%;
  background:#080a10;
  box-sizing: border-box;
  width: 400px;
}

#create-room-form h2, #join-room-form h2 {
  font-size:40px;
  text-align:center;
}

#create-room-form input, #join-room-form input {
  background:transparent;
  border:none;
  border-bottom:4px solid #151c2f;
  border-radius:0;
  padding:15px 0;
  width:100%;
  display:inline-block;
  color:#FFF;
  margin-bottom:15px;
  box-sizing:border-box;
}

#create-room-form button, #join-room-form button {
  border:none;
  border-radius:40px;
  padding:15px;
  width:100%;
  display:inline-block;
  box-sizing:border-box;
  background: #10cc4e;
  font-size:24px;
  font-weight:bold;
}
.js-range-slider{
  margin-bottom: 0 !important;
}
.mybox{
  float:left !important;
}

.create-room-form, .join-room-form{
  z-index: 4 !important;
}

.inviteLink{
  background-color: transparent; 
  border: 2px solid #4CAF50;
  color: #4CAF50;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}
.inviteLink:hover{
  background-color: #4CAF50;
  color: white;
}

.leave-room{
  position: absolute;
  height: 32px;
  top:1vh;
  left:0vw;
}

.giveChipsToUser{   
  box-sizing: border-box;
  width: 20%;
  padding: 6px;
  margin: 3px;
  margin-left: 40%;
  border: 1px solid greenyellow;
  cursor: pointer;
}

.giveChipsToUser:hover{
  color: #000;
  background-color: greenyellow;
}

.logoutButton{
  position: relative;
  height: 22px;
  top:18vh;
  left:-38.9vw;
  background-color: red;
  color: white;
  border-radius: 15px;
}
.addMoreChipsButton{
  position: relative;
  height: 22px;
  top:3vh;
  right:-39.9vw;
  background-color: black;
  color: white;
  border-radius: 15px;
}

.create-room {
  width:251px;
  height:75px;
  display:inline-block;
  text-align:center;
  color:#201104;
  font-weight:900;
  text-decoration:none;
  background:rgb(243, 243, 1);
  font-size:30px;
  padding-top:10px;
  margin:40px 20px 0;
  border-radius: 30px;
  /* temp code ahead */
  margin-bottom: 30px;
}

.play-now {
  width:251px;
  height:75px;
  display:inline-block;
  text-align:center;
  color:#201104;
  font-weight:900;
  text-decoration:none;
  background:greenyellow;
  font-size:30px;
  padding-top:10px;
  margin:40px 20px 0;
  border-radius: 30px;
}


.swiper-container {
  width: 100%;
  height: 500px;
}
.swiper-slide {
  opacity:.5;
  font-size:35px;
  text-align:center;
  cursor: pointer;
}
.swiper-slide:hover{
  font-size: 40px;
  color: #e4976e;
}
.swiper-slide-prev, .swiper-slide-next {
  opacity:.75;
  font-size:24px;
}
.swiper-slide-active {
  font-size: 45px;
  opacity:1;
  color:#d4916c;
}

.table-user {
  display:flex;
  flex-direction:column;
  align-items: center;
}

.table-user img {
  width:50px;
  height:auto;
  border-radius:50%;
  margin-top:-50px;
  margin-bottom:10px;
  border:3px solid #408981
}

.table-user span {
  opacity:.7;
}


.table-info {
  width:100%;
  background: url("../../images/lobby-table.png") no-repeat center center / contain;
  display:flex;
  flex-direction:column;
  height:200px;
  padding:50px 30px;
  align-items: center;
  justify-content: space-evenly;
  box-sizing:border-box
}
.table-info span {
  font-size:18px;
  color:#FFF;
  font-weight:900;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}
.table-info span:last-child {
  font-size:26px;
  display:flex;
  justify-content: center;
  line-height: 27px;
}
.table-info span:last-child img {
  margin-right:10px;
}
.table-info span:last-child img:last-child {
  margin-left:30px;
}

.reportBugButton{
  position: absolute !important;
  right: 0% !important;
  top: 1% !important;
  z-index: 18;
}



.message-container {
  position: absolute;
  top: 10%;
  left: 1%;
  background: transparent;
  width: 14vw;
  z-index: 15;
  max-height: 19vh;
  text-align: left;
  overflow-y: scroll;
}

.message-container::-webkit-scrollbar {
  width: 0em;
}
 
.message-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* .message-container::-webkit-scrollbar {
  display: none;
} */

.message-container .message {
  color: white;
}

.poker .seat {
  position: absolute;
  font-size: 19px;
  color: #fff;
}

.poker .seat svg {
  position: absolute;
  z-index: 2;
  /* stroke-dashoffset: 0;
  stroke-dasharray: 500;*/
  transform: rotate(-90deg); 
}

/* svg.timer {
  animation-name: circle;
  animation-duration: 10s;
  animation-timing-function: linear;
} */

.poker .seat > img {
  width: 130px;
  height: auto;
  max-width: 400px;
  min-width: 70px;
  min-height: 130px;
  border-radius: 50%;
  border: 4px solid transparent;
  z-index: 2;
  position: relative;
  cursor: pointer;
}

.poker .seat .remote-video {
  width: 130px;
  height: auto;
  /* max-width: 400px;
  min-width: 70px; */
  min-height: 130px;
  border-radius: 50%;
  border: 4px solid transparent;
  z-index: 3;
  position: absolute;
  top: 0%;
  cursor: pointer;
}

.remote-video {
  border-radius: 50%;
}
.remote-video div {
  border-radius: 50%;
  top: 0;
  background-color: transparent !important;
}
.remote-video video {
  position: relative !important;
  top:0.5% !important;
  border-radius: 50%;
}

.poker .seat > .stream.timer-img {
  animation-name: border;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.player1 {
  top: 8%;
  left: 18%;
}

.player2 {
  top: 8%;
  right: 16%;
}

.player3 {
  top: 43%;
  right: 10.5%;
}

.player4 {
  top: 60%;
  left: 45%;
}

.main > img {
  transform: scale(1.3);
}

.main > svg {
  transform: scale(1.3) rotate(-90deg) !important;
}

.player5 {
  top: 43%;
  left: 11.5%;
}

.player-name {
  position: absolute;
  top: -24px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.main .player-name {
  top: -40px;
}

.balance {
  width: 100%;
  text-align: center;
  font-size: 22px;
}

.player1 .balance {
  top: 1vh;
  position: absolute;
}

.player2 .balance {
  top: 1vh;
  left: -100%;
  position: absolute;
}

.player3 .balance {
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 100%;
}

.player5 .balance {
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 100%;
}

.main .balance {
  width: 100%;
  position: absolute;
  bottom: -60px;
  left: 17%;
  margin: 0 auto;
  padding: 6px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 20px;
  z-index: 6;
}

.badge {
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.5s ease;
  z-index: 4;
}

.badge.sb {
  background: #fcaa3f;
  color: #4e3a24;
}

.badge.bb {
  background: #c93c3c;
  color: #fff;
}

.badge.dl {
  background: #2b3453;
  color: #fff;
}

.player1-badge {
  top: 19%;
  left: 18%;
}

.player2-badge,
.player2-badge-initial {
  top: 19%;
  left: 81%;
}

.player3-badge,
.player3-badge-initial {
  top: 54%;
  left: 86%;
}

.player4-badge {
  top: 58%;
  left: 42.5%;
}

.player5-badge {
  top: 54%;
  left: 11%;
}

.seat:not(.main) .cards,
.seat:not(.main) .user-cards-main {
  position: absolute;
  right: -40px;
  bottom: 0px;
  width: 72px;
  height: 70px;
  z-index: 3;
}

.seat:not(.main) .cards div,
.seat:not(.main) .user-cards-main div {
  position: absolute;
  width: 72px;
  left: 0;
}

.seat:not(.main) .cards div:last-child {
  transform: rotate(20deg);
  left: 25px !important;
}

.main .cards,
.main .user-cards-main {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 5;
}

.main .cards div,
.main .user-cards-main div {
  width: 72px;
  position: absolute;
  margin-top: -40px;
}

.player2 .cards,
.player2 .user-cards-main {
  left: -40px;
}

.player3 .cards,
.player3 .user-cards-main {
  left: -80px;
}

.main .cards div:first-child,
.main .user-cards-main div:first-child {
  left: 0;
  transform: rotate(-20deg);
}
.main .cards div:last-child,
.main .user-cards-main div:last-child {
  right: 0;
  transform: rotate(10deg);
}

.start-game{
  position: absolute;
  top: 6%;
  left: 47%;
  z-index: 25;
  border-radius: 30%;
  transform: scale(1.3);
}

.loader{
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(17, 29, 65);
  text-align: center;
  z-index: 100;
}

.loader img{
  position: absolute;
  top: 30%;
  left: 47%;
  z-index: 101;
}
.loader figcaption{
  position: relative;
  top:45%
}
.loader img{
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .loader img {
    animation: loader-logo-spin infinite 2s linear;
  }
}

@keyframes loader-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide {
  display: none !important;
}

.action {
  position: absolute;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 22px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  z-index: 1;
  transition: ease-in 1s;
}

.action img {
  width: 35px;
  margin-right: 10px;
}

.main .action {
  left: -120px;
  top: 20px;
}

.player3 .action {
  top: 30px;
  left: -110px;
}

.player5 .action {
  top: 30px;
  right: -110px;
}

.player1 .action {
  top: 35px;
  right: -150px;
}

.player2 .action {
  top: 35px;
  left: -150px;
}

.action span {
  overflow: hidden;
}

.buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 15;
  overflow: hidden;
  padding-top: 20px;
}

input {
  outline: none;
}

button {
  cursor: pointer;
  outline: none;
}

.buttons div {
  width: 40%;
  position: relative;
  margin-bottom: 0px;
}

.buttons-left {
  float: left;
}
.button-sitout {
  background-color: tomato;
}

.buttons-right {
  float: right;
  left: 10%;
}

.buttons div button {
  border-radius: 60px;
  font-size: 15px;
  text-align: center;
  width: 32%;
  margin: 5%;
  /* display: inline-block; */
  color: #000;
  padding: 20px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 800;
  position: relative;
  bottom: 0;
}

.button-fold {
  background: #e84545;
}

.button-check {
  border: 3px solid rgba(255, 255, 255, 0.5);
  background-color: #fff !important;
  color: #000 !important;
}

.button-call {
  background: #10cc4e;
}

.button-bet {
  background: #fcaa3f;
}

.table-amount-container {
  left: 50%;
  position: absolute;
  top: 20%;
}

.table-cards-container {
  left: 50%;
  position: absolute;
  top: 29%;
}

.table-amount {
  background: rgba(0, 0, 0, 0.6);
  display: inline-flex;
  align-items: center;
  border-radius: 25px;
  padding-right: 20px;
  font-size: 22px;
  position: relative;
  left: -50%;
  opacity: 1;
}

.table-amount img {
  width: 45px;
  position: relative;
  left: -15px;
}

.table-cards {
  position: relative;
  left: -50%;
}

.main-card {
  width: 85px;
  margin-right: 1vw;
  display: inline-block;
  box-sizing: border-box;
}

.main-card img {
  position: absolute;
  width: 90px;
  height: 129px;
}

.table-amount span {
  width: auto;
  overflow: hidden;
}

.result {
  position: absolute;
  z-index: 7;
  background: rgba(0, 0, 0, 0.8);
  top: -30px;
  padding: 5px;
  width: 100%;
  left: 0;
  text-align: center;
  margin-left: -5px;
  font-size: 24px;
  border-radius: 25px;
  overflow: hidden;
  white-space: nowrap;
}

/* .card-hidden {
  
  /* transform: scaleX(0);
  height: 129px;
  } */

.bet {
  display: flex;
  width: 140px;
  height: 70%;
  /* position: absolute; */
  top: 15%;
  right: 4%;
  padding: 0px 0;
}

.bet .col-left {
  width: 120px;
  z-index: 13;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  text-decoration: none;
  float: left;
}

.bet .col-left button {
  background-color: transparent;
  color: #fff;
  border-color: transparent;
  font-size: 30px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
}

.bet .col-left input {
  background: transparent;
  /* width: auto; */
  display: inline;
  padding: 4px;
  border-radius: 25px;
  font-size: 28px;
  color: #fff;
  border: none;
  text-align: center;
  font-weight: bold;
  /* box-sizing: border-box; */
}

.bet .col-right {
  float: right;
  position: relative;
  width: 30px;
  text-align: center;
}

.progress-bar {
  position: relative;
  background: transparent;
  height: 100%;
  width: 6px;
  margin: 0 auto;
  top: 0;
}

/* .progress {
  width: 6px;
  height: 20%;
  position: absolute;
  bottom: 0;
  color: #10cc4e;
} */

.progress-bar input {
  height: 100%;
  width: 9px;
  right: 8px;
  z-index: 20;
  border-radius: 6px;
}

input[type="range"][orient="vertical"]{
  -moz-appearance: none;
  -webkit-appearance: slider-vertical;
}

/* input[type="range"]::-moz-range-progress,
input[type="range"][orient="vertical"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: #4ef500;
  width: 9px;
  border-radius: 6px;
}
input[type="range"]::-moz-range-thumb,
input[type="range"][orient="vertical"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #4ef500;
  height: 20px;
  width: 20px;
  border-radius: 50%;
} */



  /* .progress:after {
    width: 20px;
    height: 20px;
    background: #10cc4e;
    position: absolute;
    content: " ";
    display: block;
    border-radius: 50%;
    margin-left: -7px;
    bottom: -5px;
  }

  .progress:before {
    width: 27px;
    height: 27px;
    background: #10cc4e;
    position: absolute;
    content: " ";
    display: block;
    border-radius: 50%;
    margin-left: -10px;
    top: -5px;
  } */

.bet-plus,
.bet-minus {
  font-size: 72px !important;
}

.user-cards-main {
  visibility: visible;
}

.user-cards-main img:first-child {
  transform: scaleX(0);
}

.user-cards-main img:last-child {
  transform: scaleX(0);
  left: 15px;
}

.card {
  background-image: url("../../images/deck.png");
  background-size: 1040px auto;
  border-radius: 8px 8px 0 0;
  height: 100%;
  /* box-shadow: 0 0 3px 0 #808080; */
  overflow: hidden;
  width: 72px;
  height: 107px;
}
.card.card-As {
  background-position: 0.2% 0.5%;
}
.card.card-Ah {
  background-position: 0.2% 99.3%;
}
.card.card-Ad {
  background-position: 0.2% 66.5%;
}
.card.card-Ac {
  background-position: 0.2% 33.3%;
}
.card.card-Ks {
  background-position: 7.9% 0.5%;
}
.card.card-Kh {
  background-position: 7.9% 99.3%;
}
.card.card-Kd {
  background-position: 7.9% 66.5%;
}
.card.card-Kc {
  background-position: 7.9% 33.3%;
}
.card.card-Qs {
  background-position: 15.5% 0.5%;
}
.card.card-Qh {
  background-position: 15.5% 99.3%;
}
.card.card-Qd {
  background-position: 15.5% 66.5%;
}
.card.card-Qc {
  background-position: 15.5% 33.3%;
}
.card.card-Js {
  background-position: 23.2% 0.5%;
}
.card.card-Jh {
  background-position: 23.2% 99.3%;
}
.card.card-Jd {
  background-position: 23.2% 66.5%;
}
.card.card-Jc {
  background-position: 23.2% 33.3%;
}
.card.card-Ts {
  background-position: 30.9% 0.5%;
}
.card.card-Th {
  background-position: 30.9% 99.3%;
}
.card.card-Td {
  background-position: 30.9% 66.5%;
}
.card.card-Tc {
  background-position: 30.9% 33.3%;
}
.card.card-9s {
  background-position: 38.6% 0.5%;
}
.card.card-9h {
  background-position: 38.6% 99.3%;
}
.card.card-9d {
  background-position: 38.6% 66.5%;
}
.card.card-9c {
  background-position: 38.6% 33.3%;
}
.card.card-8s {
  background-position: 46.3% 0.5%;
}
.card.card-8h {
  background-position: 46.3% 99.3%;
}
.card.card-8d {
  background-position: 46.3% 66.5%;
}
.card.card-8c {
  background-position: 46.3% 33.3%;
}
.card.card-7s {
  background-position: 53.8% 0.5%;
}
.card.card-7h {
  background-position: 53.8% 99.3%;
}
.card.card-7d {
  background-position: 53.8% 66.5%;
}
.card.card-7c {
  background-position: 53.8% 33.3%;
}
.card.card-6s {
  background-position: 61.5% 0.5%;
}
.card.card-6h {
  background-position: 61.5% 99.3%;
}
.card.card-6d {
  background-position: 61.5% 66.5%;
}
.card.card-6c {
  background-position: 61.5% 33.3%;
}
.card.card-5s {
  background-position: 69.2% 0.5%;
}
.card.card-5h {
  background-position: 69.2% 99.3%;
}
.card.card-5d {
  background-position: 69.2% 66.5%;
}
.card.card-5c {
  background-position: 69.2% 33.3%;
}
.card.card-4s {
  background-position: 76.8% 0.5%;
}
.card.card-4h {
  background-position: 76.8% 99.3%;
}
.card.card-4d {
  background-position: 76.8% 66.5%;
}
.card.card-4c {
  background-position: 76.8% 33.3%;
}
.card.card-3s {
  background-position: 84.5% 0.5%;
}
.card.card-3h {
  background-position: 84.5% 99.3%;
}
.card.card-3d {
  background-position: 84.5% 66.5%;
}
.card.card-3c {
  background-position: 84.5% 33.3%;
}
.card.card-2s {
  background-position: 92.1% 0.5%;
}
.card.card-2h {
  background-position: 92.1% 99.3%;
}
.card.card-2d {
  background-position: 92.1% 66.5%;
}
.card.card-2c {
  background-position: 92.1% 33.3%;
}
.card.card-back {
  background: url("../../images/card.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.custom-card-back{
  background: url("../../images/cards-back.png");
}

.card-back1{
  background-position: 2% 5%; 
}
.card-back2{
  background-position: 27% 11%; 
}
.card-back3{
  background-position: 98% 18%; 
}
.card-back4{
  background-position: 4% 79%; 
}

.final-result {
  height: 150px;
  background: #070912;
  position: absolute;
  width: 100%;
  top: 30%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
  z-index: 1;
}

.user-final-result {
  width: 100%;
  position: absolute;
  text-align: center;
  top: -25px;
  z-index: 10;
  padding: 10px;
  border-radius: 30px;
  font-size: 24px;
  font-weight: bold;
  left: 0;
  box-sizing: border-box;
}

.user-winner {
  background: #ffac29;
  color: #000;
}

.user-lose {
  background: #ee3636;
  color: #fff;
}

.blocker {
  z-index: 11;
}

.modal-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.modal-wrap .modal {
  padding: 32px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  top: 18%;
  width: 440px;
  z-index: 101;
}
.modal-wrap .modal .red {
  background-color: red;
}
.modal-wrap .modal label {
  display: block;
  font-size: large;
  margin-bottom: 10px;
}
.modal-wrap .modal .input-left {
  margin-right: 32px;
  color: #000;
  width: 50%;
}
.modal-wrap .curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}


.leader-board {
  position: fixed;
  bottom: 6%;
  width: 315px;
  padding: 0 1.25rem;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  z-index: 1020;
}

.leader-board.is-active {
  -webkit-transform: translateY(90%);
          transform: translateY(90%);
}

.leader-board .section-header {
  padding: 1.2rem 0;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.leader-board .section-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300;
}

.leader-board .section-header h2 span {
  background: #b22113;
  color: white;
  font-weight: 700;
  padding: .25rem .875rem;
  border-radius: 4px;
  margin-right: .1rem;
}

.leader-board .section-header .toggle-button {
  width: 32px;
  height: 32px;
  background: #b22113;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  cursor: pointer;
}

.leader-board .leaders-list {
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  font-size: .875rem;
}

.leader-board .leaders-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: list;
}

.leader-board .leaders-list ul .leader-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: .5rem 1rem;
  padding-left: 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.leader-board .leaders-list ul .leader-item .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.leader-board .leaders-list ul .leader-item .profile img {
  width: 32px;
  border-radius: 50%;
  margin-right: 1rem;
}

.leader-board .leaders-list ul .leader-item:last-child {
  border: none;
}

.leader-board .leaders-list ul .leader-item::after {
  position: absolute;
  left: 1rem;
  counter-increment: list;
  content: counters(list, ".") " ";
}

/* .modal {
  background: #080a10;
  box-sizing: border-box;
}

.modal h2 {
  font-size: 40px;
  text-align: center;
}

.modal input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  padding: 15px;
  width: 100%;
  display: inline-block;
  color: #fff;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.modal button {
  border: none;
  border-radius: 40px;
  padding: 15px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  background: #10cc4e;
  font-size: 24px;
  font-weight: bold;
} */

@keyframes circle {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -500;
  }
}

@keyframes border {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(50, 94, 111, 0.25);
    -moz-box-shadow: 0px 0px 0px 0px rgba(50, 94, 111, 0.25);
    box-shadow: 0px 0px 0px 0px rgba(50, 94, 111, 0.25);
  }
  50% {
    -webkit-box-shadow: 0px 0px 0px 8px rgba(50, 94, 111, 0.75);
    -moz-box-shadow: 0px 0px 0px 8px rgba(50, 94, 111, 0.75);
    box-shadow: 0px 0px 0px 8px rgba(50, 94, 111, 0.75);
  }
  100% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(50, 94, 111, 0.25);
    -moz-box-shadow: 0px 0px 0px 0px rgba(50, 94, 111, 0.25);
    box-shadow: 0px 0px 0px 0px rgba(50, 94, 111, 0.25);
  }
}

.game-play {
  overflow: hidden;
}

.poker {
  background: none !important;
  width: 900px;
  height: 630px;
}

.poker .game-table {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.poker .game-table .poker-table {
  position: absolute;
  top: 100px;
}

.poker .seat {
  position: absolute;
  font-size: 1rem;
  margin-top: 85px;
}

.poker .seat .player-profile {
  width: 138px;
  height: 138px;
}

.poker .seat.player1 {
  top: 0%;
  left: 2%;
}

.poker .seat.player2 {
  top: 0%;
  right: 2%;
}

.poker .seat.player3 {
  top: 40%;
  right: -5%;
}

.poker .seat.player4 {
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.poker .seat.player5 {
  top: 40%;
  left: -5%;
}

.poker .seat.main .cards img:last-child {
  left: 60px;
}

.poker .seat.main .balance {
  width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  padding: 6px 1rem;
}

.poker .seat.main .result {
  width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  padding: 6px 1rem;
}

.poker .seat .user-cards-main {
  z-index: 1020;
}

.poker .seat .seat1 {
  cursor: pointer;
  border-radius: 50%;
  background: rgba(23, 31, 55, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 4px solid white;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  position: relative;
}

.poker .seat .seat1::after {
  content: 'Seat';
  position: absolute;
  margin: 0 auto;
  top: 1.5rem;
  font-weight: 700;
  font-size: 1.25rem;
}

.poker .seat .seat1 svg {
  margin-top: 1.5rem;
  width: 50% !important;
  height: 50% !important;
  display: block !important;
}

.poker .seat .seat2 {
  cursor: pointer;
  border-radius: 50%;
  background: rgba(23, 31, 55, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 4px solid white;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  position: relative;
}

.poker .seat .seat2::after {
  content: 'Seat';
  position: absolute;
  margin: 0 auto;
  top: 1.5rem;
  font-weight: 700;
  font-size: 1.25rem;
}

.poker .seat .seat2 svg {
  margin-top: 1.5rem;
  width: 50% !important;
  height: 50% !important;
  display: block !important;
}

.poker .buttons {
  overflow: visible;
  background: transparent;
  -webkit-transition: background .1s ease-in-out;
  transition: background .1s ease-in-out;
}

.poker .buttons.is-active {
  z-index: 1030;
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(transparent));
  background: linear-gradient(0deg, black, transparent);
}

.poker .buttons .buttons-left, .poker .buttons .buttons-right {
  width: 50%;
}

.poker .buttons .buttons-left a, .poker .buttons .buttons-right a {
  -webkit-box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.4), inset -1px -1px 2px rgba(0, 0, 0, 0.4), 0 10px 20px rgba(0, 0, 0, 0.25);
          box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.4), inset -1px -1px 2px rgba(0, 0, 0, 0.4), 0 10px 20px rgba(0, 0, 0, 0.25);
  text-transform: unset;
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: .8;
}

.poker .buttons .buttons-left a:first-child, .poker .buttons .buttons-right a:first-child {
  margin-left: 0;
}

.poker .buttons .buttons-left a:last-child, .poker .buttons .buttons-right a:last-child {
  margin-right: 0;
}

.poker .buttons .buttons-left a:hover, .poker .buttons .buttons-right a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 1;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .poker .buttons {
    height: 100px;
  }
}

@media (max-width: 900px) {
  .poker .buttons {
    height: 100px;
    position: fixed;
    left: 0;
    padding: 0 2rem 0rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .poker .buttons .buttons-left a, .poker .buttons .buttons-right a {
    font-size: 1rem;
    font-weight: 600;
  }
}

.poker .table-cards-container {
  left: 50%;
  position: absolute;
  top: 35%;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .poker {
    width: 700px;
    height: 700px;
  }
  .poker .seat .player-profile {
    width: 120px;
    height: 120px;
  }
  .poker .table-amount-container {
    top: 32%;
  }
  .poker .table-cards-container {
    top: 38%;
  }
  .poker .table-cards-container .table-cards .main-card {
    width: 60px;
  }
  .poker .table-cards-container .table-cards .main-card img {
    width: 75px;
    height: 105px;
  }
}

@media (max-width: 900px) {
  .poker {
    width: 550px;
    height: 500px;
  }
  .poker .game-table .poker-table {
    top: 100px;
  }
  .poker .seat {
    margin-top: 50px;
    font-size: .75rem;
  }
  .poker .seat .result {
    font-size: 1rem;
  }
  .poker .seat .player-profile {
    width: 80px;
    height: 80px;
  }
  .poker .seat .balance {
    font-size: 1rem;
  }
  .poker .seat.main .balance {
    bottom: -40px;
  }
  .poker .seat .action {
    font-size: 1rem;
  }
  .poker .seat .action img {
    width: 28px;
  }
  .poker .seat.main .cards img {
    width: 50px;
  }
  .poker .seat.main .cards img:last-child {
    left: 40px;
  }
  .poker .table-amount-container {
    top: 32%;
  }
  .poker .table-amount-container .table-amount {
    font-size: 1rem;
  }
  .poker .table-amount-container .table-amount img {
    width: 30px;
  }
  .poker .table-cards-container {
    top: 38%;
  }
  .poker .table-cards-container .table-cards .main-card {
    width: 50px;
  }
  .poker .table-cards-container .table-cards .main-card img {
    width: 50px;
    height: 75px;
  }
}

.final-result {
  height: 150px;
  position: fixed;
  top: 35%;
}

.final-result .winner-card {
  margin-left: 1.5rem;
}

.final-result .winner-card img {
  width: 40px;
  padding: 0;
  margin: 0;
}

.badge {
  z-index: 0;
}

.badge.player1-badge {
  top: 25%;
  left: 15%;
}

.badge.player2-badge {
  top: 25%;
  left: 81%;
}

.badge.player3-badge {
  top: 54%;
  left: 86%;
}

.badge.player4-badge {
  top: 58%;
  left: 42.5%;
}

.badge.player5-badge {
  top: 50%;
  left: 5%;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .badge.player1-badge {
    top: 25%;
    left: 20%;
  }
  .badge.player2-badge {
    top: 25%;
    left: 75%;
  }
  .badge.player5-badge {
    top: 50%;
    left: 10%;
  }
  .badge.player3-badge {
    top: 54%;
    left: 80%;
  }
}

@media (max-width: 900px) {
  .badge {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}

.poker-header {
  position: fixed;
  width: 100%;
  padding: 10px 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.poker-header .header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.poker-header .header-left .header-user {
  background: transparent;
  padding: 0;
  margin: 0 1rem;
}

.poker-header .header-left .header-user img {
  height: 32px;
  width: 32px;
  margin-right: 1rem;
}

.poker-header .header-left .header-user span {
  font-size: 1rem;
}

.poker-header .header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.poker-header .header-right .header-amount {
  background: transparent;
}

.poker-header .header-right .header-amount .amount-icon {
  width: 32px;
  height: 32px;
  margin: 0 1rem;
  left: 0;
}

.poker-header .header-right .header-amount span {
  font-size: 1rem;
}

@media (max-width: 900px) {
  .poker-header {
    width: 300px;
    height: 100%;
    padding: 2rem 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    background: -webkit-gradient(linear, left top, right top, color-stop(58%, #13192e), to(rgba(19, 25, 46, 0)));
    background: linear-gradient(to right, #13192e 58%, rgba(19, 25, 46, 0) 100%);
    z-index: 1050;
    padding-top: 4rem;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  }
  .poker-header > * {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .poker-header .header-left, .poker-header .header-right {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .poker-header .header-left > a, .poker-header .header-right > a {
    position: absolute;
    bottom: 10%;
  }
  .poker-header .header-left > a.btn-ghost, .poker-header .header-right > a.btn-ghost {
    bottom: calc(10% + 3.5rem);
  }
  .poker-header .header-left div, .poker-header .header-right div {
    margin: 2rem 0;
  }
  .poker-header .header-left .header-amount, .poker-header .header-right .header-amount {
    padding: 0;
    position: relative;
  }
  .poker-header .header-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .poker-header .roomdID {
    display: none;
  }
  .poker-header.is-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.burger-menu {
  position: absolute;
  display: none;
  left: 1rem;
  top: 1rem;
  width: 45px;
  height: 45px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.875rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 50%;
  z-index: 1060;
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background: rgba(23, 30, 55, 0.5);
}

.burger-menu.is-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

@media (max-width: 900px) {
  .burger-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.bet {
  /* background: -webkit-gradient(linear, right top, left top, from(#13192e), to(rgba(19, 25, 46, 0)));
  background: linear-gradient(to left, #13192e 0%, rgba(19, 25, 46, 0) 100%); */
  height: 70%;
  position: fixed;
  z-index: 1090;
}

.button {
  color: white;
  text-decoration: none;
  padding: .5rem 2rem;
  display: block;
  margin: 0 1rem;
  border-radius: 30px;
}

.button.btn-ghost {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.button.btn-ghost:hover {
  background: rgba(255, 255, 255, 0.1);
}

.button.btn-red {
  background: #c93c3c;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.button.btn-red:hover {
  background: #d15858;
}

.button ion-icon {
  position: relative;
  top: 2px;
  color: white;
  margin-right: .475rem;
}

.btn-buy {
  background: #10CC4E;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.btn-buy ion-icon {
  color: white;
  font-size: 1.5rem;
}

.leader-board {
  position: fixed;
  /* bottom: 0; */
  width: 315px;
  padding: 0 1.25rem;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  z-index: 1020;
  visibility: hidden;
}

.leader-board.is-active {
  -webkit-transform: translateY(90%);
          transform: translateY(90%);
}

.leader-board .section-header {
  padding: 1.2rem 0;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.leader-board .section-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300;
}

.leader-board .section-header h2 span {
  background: #b22113;
  color: white;
  font-weight: 700;
  padding: .25rem .875rem;
  border-radius: 4px;
  margin-right: .1rem;
}

.leader-board .section-header .toggle-button {
  width: 32px;
  height: 32px;
  background: #b22113;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  cursor: pointer;
}

.leader-board .leaders-list {
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  font-size: .875rem;
}

.leader-board .leaders-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: list;
}

.leader-board .leaders-list ul .leader-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: .5rem 1rem;
  padding-left: 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.leader-board .leaders-list ul .leader-item .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.leader-board .leaders-list ul .leader-item .profile img {
  width: 32px;
  border-radius: 50%;
  margin-right: 1rem;
}

.leader-board .leaders-list ul .leader-item:last-child {
  border: none;
}

.leader-board .leaders-list ul .leader-item::after {
  position: absolute;
  left: 1rem;
  counter-increment: list;
  content: counters(list, ".") " ";
}

.ads-position {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

@media (max-width: 1100px) {
  .ads-position {
    display: none;
  }
}
/*# sourceMappingURL=game-room.css.map */