@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  background:#0e1323;
  font-family: 'Roboto', sans-serif;
  color:#FFF;
  overflow-x:hidden;
}


input {
  background:transparent;
  border:none;
  border-bottom:4px solid #151c2f;
  border-radius:0;
  padding:15px 0;
  width:100%;
  display:inline-block;
  color:#FFF;
  margin-bottom:15px;
  box-sizing:border-box;
}

button {
  border:none;
  border-radius:40px;
  padding:15px;
  width:100%;
  display:inline-block;
  box-sizing:border-box;
  background: #10cc4e;
  font-size:24px;
  font-weight:bold;
}

input:focus {
  outline:none;
}

.poker {
  width:100vw;
  max-width:1334px;
  height:100vh;
  background:url('../../images/table.png') no-repeat top center;

  margin:0 auto;
  position:relative;
}

.poker .seat {
  position:absolute;
  font-size:19px;
  color:#FFF;
}

.poker .seat svg {
  position:absolute;
  z-index:3;
  /* stroke-dashoffset: 0;
  stroke-dasharray: 500; */
  transform:rotate(-90deg);  
}

svg.timer {
  animation-name: circle;
  animation-duration: 10s;
  animation-timing-function: linear;
}

.poker .seat > img, .poker .seat video {
  width:130px;
  height:auto;
  max-width:400px;
  min-width:70px;
  border-radius:50%;
  border:4px solid rgba(84,163,176,.6);
  z-index:2;
  position:relative;
}

.poker .seat > img.timer-img {
  animation-name: border;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.player1 {
  top:8%;
  left:18%;
}

.player2 {
  top:8%;
  right:16%;
}

.player3 {
  top:43%;
  right:10.5%;
}

.player4 {
  top:50%;
  left:45%;
}

.main > img, .main > svg {
  transform: scale(1.3);
}

.main > svg {
  transform: scale(1.3) rotate(-90deg) !important;
}

.player5 {
  top:43%;
  left:11.5%;
}

.player-name {
  position:absolute;
  top:-30px;
  width:100%;
  text-align:center;
}

.balance {
  width:100%;
  text-align:center;
  font-size:22px;
}

.player1 .balance {
  top:1vh;
  position:absolute;
}

.player2 .balance {
  top:1vh;
  left:-100%;
  position:absolute;
}

.player3 .balance {
  position: absolute;
  bottom:-32px;
  left:0;
  width:100%;
}

.player5 .balance {
  position: absolute;
  bottom:-32px;
  left:0;
  width:100%;
}

.main .balance {
  width:100%;
  position:absolute;
  bottom:-80px;
  left:0;
  padding:6px;
  background:rgba(0,0,0,.9);
  border-radius:20px;
  z-index:6;
}

.badge {
  width:35px;
  height:35px;
  position:absolute;
  border-radius:50%;
  text-align:center;
  padding-top:10px;
  box-sizing: border-box;
  font-size:14px;
  font-weight:bold;
  transition: all 0.5s ease;
  z-index:4;
}

.badge.sb {
  background:#fcaa3f;
  color:#4e3a24;
}

.badge.bb {
  background:#c93c3c;
  color:#FFF;
}

.badge.dl {
  background:#2b3453;
  color:#FFF;
}

.player1-badge, .player1-badge-initial {
  top:19%;
  left:18%;
}

.player2-badge, .player2-badge-initial {
  top:19%;
  left:81%;
}

.player3-badge, .player3-badge-initial {
  top:54%;
  left:86%;
}

.player4-badge, .player4-badge-initial {
  top:58%;
  left:42.5%;
}

.player5-badge, .player5-badge-initial {
  top:54%;
  left:11%;
}

.seat:not(.main) .cards, .seat:not(.main) .user-cards-main {
  position:absolute;
  right:-40px;
  bottom:0px;
  width:40px;
  height:70px;
} 

.seat:not(.main) .cards img, .seat:not(.main) .user-cards-main img {
  position:absolute;
  width:40px;
  left:0;
} 

.seat:not(.main) .cards img:last-child {
  transform:rotate(25deg);
  left:15px !important;
}

.player2 .cards, .player2 .user-cards-main {
  left:-80px;
}

.player3 .cards, .player3 .user-cards-main {
  left:-80px;
}

.main .cards, .main .user-cards-main {
  position:absolute;
  width:100%;
  left:0;
  z-index:5;
}

.main .cards img, .main .user-cards-main img {
  width:80px;
  position:absolute;
  margin-top:-40px;
}

.main .cards img:first-child, .main .user-cards-main img:first-child {
  left:0;
  transform: rotate(-20deg);
}
.main .cards img:last-child, .main .user-cards-main img:last-child {
  right:0;
  transform: rotate(10deg);
}

.action {
  position:absolute;
  display:flex;
  align-items: center;
  font-weight:800;
  font-size:22px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  z-index:1;
}

.action img {
  width:35px;
  margin-right:10px;
}

.main .action {
  left:-120px;
  top:20px;
}

.player5 .action {
  top:30px;
  right:-110px;
}

.player1 .action {
  bottom:-25px;
  right:-80px;
}

.player2 .action {
  bottom:-25px;
  left:-80px;
}

.player3 .action {
  top:30px;
  left:-110px;
}

.action span {
  overflow:hidden;
}

.buttons {
  width:100%;
  position:absolute;
  bottom:0;
  overflow:hidden;
  padding-top:20px;
}

.buttons div {
  width:40%;
  position:relative;
  margin-bottom:50px;
}

.buttons-left {
  float:left;
}

.buttons-right {
  float:right;
}

.buttons div a {
  border-radius:60px;
  font-size:20px;
  text-align:center;
  width:39%;
  margin:5%;
  display:inline-block;
  color:#000;
  padding:20px;
  box-sizing: border-box;
  text-decoration:none;
  font-weight:800;
  position:relative;
  bottom:0;
}

.button-fold {
  background:#e84545;
}

.button-check {
  border:3px solid rgba(255,255,255,.5);
  color:#FFF !important;
}

.button-call {
  background:#10cc4e;
}

.button-bet {
  background:#fcaa3f;
}

.table-amount-container {
  left:50%;
  position:absolute;
  top:25%;
}

.table-cards-container {
  left:50%;
  position:absolute;
  top:29%;
}

.table-amount {
  background:rgba(0,0,0,.6);
  display:inline-flex;
  align-items: center;
  border-radius:25px;
  padding-right:20px;
  font-size:22px;
  position:relative;
  left:-50%;
  opacity:1;
}

.table-amount img {
  width:45px;
  position:relative;
  left:-15px;
}

.table-cards {
  position:relative;
  left:-50%;
}

.main-card {
  width:85px;
  display:inline-block;
  box-sizing:border-box;
}

.main-card img {
  position:absolute;
  width:90px;
  height:129px;
}

.table-amount span {
  width:auto;
  overflow:hidden;
}

.result {
  position:absolute;
  z-index:7;
  background:rgba(0,0,0,.8);
  top:-30px;
  padding:5px;
  width:100%;
  left:0;
  text-align:center;
  margin-left:-5px;
  font-size:24px;
  border-radius:25px;
  overflow:hidden;
  white-space: nowrap;
}

.card-hidden {
  visibility: hidden;
  transform: scaleX(0);
  height:129px;
}

.bet {
  display:flex;
  width:250px;
  height:70vh;
  position:absolute;
  top:0;
  right:0;
  padding:50px 0;
}

.bet .col-left {
  width:150px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  color:#FFF;
  text-decoration: none;
  float:left;
}

.bet .col-left a {
  color:#FFF;
  font-size:30px;
  text-decoration:none;
  text-align:center;
  font-weight:bold;
}

.bet .col-left input {
  background:#080a10;
  width:auto;
  display:inline;
  padding:8px;
  border-radius:25px;
  font-size:28px;
  color:#FFF;
  border:none;
  text-align:center;
  font-weight:bold;
  box-sizing:border-box;
}

.bet .col-right {
  float:right;
  position:relative;
  width:100px;
  text-align:center;
}

.progress-bar {
  position:relative;
  background:#080a10;
  height:100%;
  width:6px;
  margin:0 auto;
}

.progress {
  width:6px;
  height:20%;
  position:absolute;
  bottom:0;
  background:#10cc4e;
}

.progress:after {
  width:20px;
  height:20px;
  background:#10cc4e;
  position:absolute;
  content:' ';
  display:block;
  border-radius:50%;
  margin-left:-7px;
  bottom:-5px;
}

.progress:before {
  width:27px;
  height:27px;
  background:#10cc4e;
  position:absolute;
  content:' ';
  display:block;
  border-radius:50%;
  margin-left:-10px;
  top:-5px;
}

.bet-plus, .bet-minus {
  font-size:72px !important;
}

.user-cards-main {
  visibility:visible;
} 

.user-cards-main img:first-child {
  transform:scaleX(0);
}

.user-cards-main img:last-child {
  transform:scaleX(0);
  left:15px;
}

.final-result {
  height:150px;
  background:#070912;
  position:absolute;
  width:100%;
  top:30%;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:48px;
  font-weight:bold;
  z-index:1;
}

.user-final-result {
  width:100%;
  position:absolute;
  text-align:center;
  top:-25px;
  z-index:10;
  padding:10px;
  border-radius:30px;
  font-size:24px;
  font-weight:bold;
  left:0;
  box-sizing:border-box;
}

.user-winner {
  background:#ffac29;
  color:#000;
}

.user-lose {
  background:#ee3636;
  color:#FFF;  
}

.blocker {
  z-index:11;
}

.modal {
  background:#080a10;
  box-sizing: border-box;
}

.modal h2 {
  font-size:40px;
  text-align:center;
}

.modal input {
  background:transparent;
  border:none;
  border-bottom:4px solid #32343b;
  border-radius:0;
  padding:15px 0;
  width:100%;
  display:inline-block;
  color:#FFF;
  margin-bottom:15px;
  box-sizing:border-box;
}

.modal button {
  border:none;
  border-radius:40px;
  padding:15px;
  width:100%;
  display:inline-block;
  box-sizing:border-box;
  background: #10cc4e;
  font-size:24px;
  font-weight:bold;
}

.user-video {
  border-radius:50%;
  overflow:hidden
} 

.poker .seat video {
  height:130px !important;
  width:130px !important;
}

.header {
  position: absolute;
  width: 100%;
  display:flex;
  top: 50px;
  justify-content: space-between;
}

.header-user {
  float:left;
  padding:0 45px;
  background:#171c2e;
  border-radius:0 50px 50px 0;
  justify-content: center;
  display:flex;
  align-items: center;
}

.header-user img {
  width:60px;
  border-radius:50%;
  float:left;
  margin-right:30px;
  position:relative;
  transform: scale(1.3);
}

.header-user span, .header-amount span {
  font-size:18px;
  color:#fbdf6e;
  font-weight:800;
}

.header-amount {
  background:#171c2e;
  padding:0 45px 0 0;
  justify-content: center;
  display:flex;
  align-items: center;
  border-radius:50px 0 0 50px;
}

.header-amount span {
  margin-right:20px;
}

.header-amount > img {
  transform: scale(1);
  position:relative;
  left:-20px;
}

.lobby-table {
  width:33.3333%;
  float:left;
  align-items:center;
  margin-top:30px;
}

.table-info {
  width:100%;
  background:url('../../images/lobby-table.png') no-repeat center center / contain;
  display:flex;
  flex-direction:column;
  height:200px;
  padding:50px 30px;
  align-items: center;
  justify-content: space-evenly;
  box-sizing:border-box
}

.table-info span {
  font-size:18px;
  color:#FFF;
  font-weight:900;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}

.table-info span:last-child {
  font-size:26px;
  display:flex;
  justify-content: center;
  line-height: 27px;
}

.table-info span:last-child img {
  margin-right:10px;
}

.table-info span:last-child img:last-child {
  margin-left:30px;
}

.table-user {
  display:flex;
  flex-direction:column;
  align-items: center;
}

.lobby-sidebar {
  width:20%;
  float:left;
  margin-top:60px;
  align-items: center;
  justify-content: center;

}

.lobby-container {
  width:80%;
  float:left;
}

.swiper-container {
  width: 100%;
  height: 500px;
}

.table-user img {
  width:50px;
  height:auto;
  border-radius:50%;
  margin-top:-50px;
  margin-bottom:10px;
  border:3px solid #408981
}

.table-user span {
  opacity:.7;
}

.stake-slider .swiper-slide {
  opacity:.2;
  font-size:20px;
  text-align:center;
}

.stake-slider .swiper-slide-prev, .stake-slider .swiper-slide-next {
  opacity:.75;
  font-size:24px;
}

.stake-slider .swiper-slide-active {
  font-size:36px;
  opacity:1;
  color:#d4916c;
}

.lobby {
  display:flex;
  min-height:calc(100vh - 120px);
}

.lobby-buttons-row {
  display:block;
  width:100%;
  text-align:center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.create-room {
  width:351px;
  height:75px;
  display:inline-block;
  text-align:center;
  color:#201104;
  font-weight:900;
  text-decoration:none;
  background:url(../../images/create-room.png) no-repeat;
  font-size:30px;
  padding-top:20px;
  margin:40px 20px 0;
}

.play-now {
  width:351px;
  height:75px;
  display:inline-block;
  text-align:center;
  color:#201104;
  font-weight:900;
  text-decoration:none;
  background:url(../../images/play-now.png) no-repeat;
  font-size:30px;
  padding-top:20px;
  margin:40px 20px 0;
}

.create-room-form {
  text-align:center;
}

.password-field input{
  width:30px;
  display:inline-block;
  margin-left:20px;
  text-align:center;
}

.irs--round .irs-handle {
  top: 30px !important;
  width: 16px !important;
  height: 16px !important;
  border: 4px solid #10cd4f !important;
  background-color: #10cd4f !important;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0,0,255,0.3);
}

.irs--round .irs-bar {
  background-color: #19223f !important;
}

.irs--round .irs-line {
  background-color: #ffffff !important;
}

.irs--round .irs-single {
  background-color: #10cd4f !important;
}

.irs--round .irs-single:before {
  border-top-color: #10cd4f !important;
}

.simple-switch-outter.unchecked {
  box-shadow: #111629 0 0 0 inset !important;
  border-color: #111629 !important;
  background-color: #111629 !important;
}

.simple-switch-outter .simple-switch-circle {
  background:url(../../images/switch.png) no-repeat !important;
  width:39px !important;
  height:39px !important;
  top: -1px !important;
}

.simple-switch-outter {
  height:36px !important;
  width:58px !important;
  float:right;
}

.simple-switch-outter.checked {
  border-color: #111629 !important;
  box-shadow: #111629 0 0 0 16px inset !important;
  background-color: #111629 !important;
}

.password-field {
  margin-top:10px;
  margin-bottom:10px;
}

.password-field span {
  font-size:20px;
  line-height:18px;
}

.password-field input {
  height:20px;
}

.create-room-modal-button {
    width: 351px !important;
    height: 75px !important;
    text-align: center;
    color: #201104;
    font-weight: 900;
    text-decoration: none;
    background: url(../../images/play-now.png) no-repeat !important;
    font-size: 30px;
    padding-top: 20px;
    margin: 30px auto 20px !important;
    display:flex !important;
    justify-content:center;
    align-content:center;
    line-height: 43px !important;
    outline:none !important;
}

.irs {
  margin:10px 0 30px !important;
}

.modal p {
  line-height:26px;
  color:#ccd0de;
}

.register {
  width:100%;
  min-height:100vh;
  background:url(../../images/register-bg.jpg) no-repeat center center / cover;
  text-align:center;
}

.register-wrapper {
  width:900px;
  margin:0 auto;
}

.register-wrapper p {
  font-size:20px;
  line-height:24px;
}

.login-buttons-row {
  margin-bottom:30px;
}

.login-buttons-row a {
  margin:0 20px;
}

.register input {
  border-color:rgba(255,255,255,.5);
  color:#FFF;
  max-width:528px;
  margin:0 auto;
  display:block;
  font-size:18px;
}

.register button {
  max-width:528px;
  margin:0 auto;
  display:block;
  /* margin-top:30px; */
}

.register-sidebar {
  text-align:left;
  padding:0 60px;
}

.register-sidebar a {
  color:#FFF;
  text-decoration: none;
  display:block;
  font-size:36px;
  margin:25px 0;
  font-weight:900;
}

.register-sidebar a:first-child {
  margin-top:0;
}

.register-table .table-info {
  width:902px;
  height:443px;
  background:url(../../images/register-table.png)
}

.register-table p {
  color: #FFF;
  font-weight: 900;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  font-size:26px;
  text-align:center;
}

.register-table .row {
  width:600px;
}

.register-table .row p, .register-table .row > a, .register-table .row form {
  width:50%;
}

.register-table p {
  margin:15px 0;
}

.register-table .row > a {
  text-decoration: none;
  display:flex;
  text-align:center;
  flex-direction: column;
  font-weight: 900;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  font-size: 26px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color:#FFF;
}

.register-table p span, .register-table p a, .register-table a span {
  font-size:16px !important;
  color:#FFF;
  line-height:27px;
}

.register-table .row {
  display:flex;
  flex-direction: row;
}

.register-table p span a {
  margin:0 5px;
}

.register-table .table-info {
  justify-content: flex-start;
}

.register-table .table-info > span {
  font-size:36px;
  margin-top:55px;
  margin-bottom:20px;
}

.register-table .table-info form input {
  border-bottom:3px solid rgba(0,0,0,.3);
  height: 40px;
  margin-bottom:5px;
}

.register-table .table-info form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFF;
  opacity: .8; /* Firefox */
}

.register-table .table-info form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFF;
  opacity: .8;
}

.register-table .table-info form input::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFF;
  opacity: .8;
}

/*
.register-table .table-info form button {
  background:transparent;
  color:#FFF;
  font-size:24px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  cursor:pointer;
} */

.register-table .table-info form button:focus {
  border:none;
  outline:0;
}

.register4 .row > p {
  margin-top:10px;
}

@keyframes circle {
  from {
    stroke-dashoffset:0;}
  to {
    stroke-dashoffset:-500;
  }
}

@keyframes border {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    -moz-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
  }
  50% {
    -webkit-box-shadow: 0px 0px 0px 8px rgba(50,94,111,0.75);
    -moz-box-shadow: 0px 0px 0px 8px rgba(50,94,111,0.75);
    box-shadow: 0px 0px 0px 8px rgba(50,94,111,0.75);
  }
  100% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    -moz-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
  }
}



@-webkit-keyframes animateStroke {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animateStroke {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

body {
  font-family: Arial, sans-serif;
}

.header {
  padding: 0 4rem;
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.header .header-user {
  border-radius: 40px;
  padding: 0 45px 0 0;
  left: 2rem;
}

.header .header-amount {
  position: absolute;
  right: 2rem;
  border-radius: 40px;
  padding: 0 0 0 0;
}

.register {
  background-image: url("../../images/main_bg.png");
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.fluid {
  max-width: 100%;
}

.col-3-1 {
  width: 33.33333%;
}

.col-3-2 {
  width: 66.66667%;
}

.col-4-3 {
  width: 75%;
}

.col-4-2 {
  width: 50%;
}

.col-4-1 {
  width: 25%;
}

[class^="Poker_col-"] {
  margin: 0 15px;
}

@media (max-width: 900px) {
  [class^="Poker_col-"] {
    width: 100%;
  }
}

.game-brand {
  font-family: 'Mr Dafoe', cursive;
  font-size: 3rem;
  color: #09a2ac;
  margin-top: -60px;
}

.game-brand span {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 10px;
}

.game-brand .brand-poker {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #b22113;
}

@media (max-width: 1280px) {
  .game-brand {
    font-size: 2rem;
  }
  .game-brand span {
    font-size: 1rem;
  }
  .game-brand .brand-poker {
    font-size: 3rem;
  }
}

.register-wrapper {
  width: 600px;
  height: auto;
}

.register-wrapper .register-box {
  width: 100%;
  position: relative;
  background: white;
  border-radius: 1.1rem;
  padding: 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
  border: 12px solid #dec08c;
}

.register-wrapper .register-box .reg-box-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  top: -80px;
}

.register-wrapper .register-box .reg-box-title span {
  font-family: 'Nunito', sans-serif;
  color: #fef49c;
  -webkit-text-stroke: 3px #e61500;
  text-shadow: 0 0 4px #e61500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: white;
  margin-left: -5px;
  height: 80px;
  width: 80px;
  padding: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 3rem;
  border-radius: 50px;
  -webkit-box-shadow: 0 6px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 0 6px 0px rgba(0, 0, 0, 0.75);
  text-transform: uppercase;
  font-weight: 700;
}

.register-wrapper .register-box .register-svg {
  position: absolute;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  left: -40px;
  top: -40px;
  z-index: -1;
}

.register-wrapper .register-box .register-svg .light-1 {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  fill: transparent;
  stroke: #fef49c;
  stroke-width: 15px;
  stroke-linecap: round;
  stroke-dasharray: 0 40;
  stroke-dashoffset: 1000;
  -webkit-animation-name: animateStroke;
          animation-name: animateStroke;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.register-wrapper .form {
  padding: 1rem 0 0 0;
}

.register-wrapper .form .form-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.register-wrapper .form .form-group {
  margin: 1rem 0;
}

.register-wrapper .form .form-group .form-input {
  font-size: 1rem;
  padding: 1rem 1.2rem;
  background: #f5f5f9;
  border: 1px solid #bebecc;
  border-radius: 6px;
  color: black;
}

.register-wrapper .form .form-group .form-input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: .2;
  /* Firefox */
}

.register-wrapper .form .form-group .form-input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: .2;
  /* Firefox */
}

.register-wrapper .form .form-group .form-input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: .2;
  /* Firefox */
}

.register-wrapper .form .form-group .form-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: .2;
  /* Firefox */
}

.register-wrapper .form .form-check {
  position: relative;
  display: block;
  color: black;
  padding: 0;
}

.register-wrapper .form .form-check .form-check-input {
  position: absolute;
  margin-top: .15rem;
  left: 0;
  margin-left: -3.5rem;
}

.register-wrapper .form .form-check label {
  padding-left: 1.5rem;
  display: inline-block;
}

.forget-password a {
  color: #09a2ac;
  text-decoration: none;
}

.btn {
  font-size: 1.25rem;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 1rem 1.2rem;
  text-decoration: none;
  margin-top: 0;
  outline: none;
  white-space: nowrap;
}

.btn.btn-primary {
  background: white;
  color: #09a2ac;
  border: 1px solid #09a2ac;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.btn.btn-primary:hover {
  background: #09a2ac;
  color: white;
}

.btn.btn-green {
  background: #10cc4e;
  color: #072510;
  border: 1px solid #10cc4e;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.btn.btn-green:hover {
  background: #21ee65;
  color: #072510;
}

.btn.btn-green.is-margin {
  margin: 1rem 0;
}

.btn.btn-primary-filled {
  background: #09a2ac;
  color: white;
  border: 1px solid #09a2ac;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.btn.btn-primary-filled:hover {
  background: #0cd0dc;
  color: white;
}

.btn.btn-red {
  background: white;
  color: #09a2ac;
  border: 1px solid #b22113;
  color: red;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.btn.btn-red:hover {
  background: #b22113;
  color: white;
}

.btn.btn-red-filled {
  background: #b22113;
  color: white;
  border: 1px solid #b22113;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.btn.btn-red-filled:hover {
  background: #e02a18;
  color: white;
}

.btn.btn-sm {
  font-size: 1rem;
}

.btn.btn-ghost {
  font-size: 1.25rem;
  display: block;
  margin-top: 30px;
  background: white;
  color: black;
  border: 1px solid white;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.btn.btn-ghost:hover {
  color: #09a2ac;
}

.btn.btn-social {
  color: white;
  font-weight: 400;
  position: relative;
  font-size: 1rem !important;
}

.btn.btn-social ion-icon {
  margin-right: 10px;
}

.btn.btn-facebook {
  background: #3b5998;
}

.btn.btn-facebook:hover {
  background: #4568b2;
}

.btn.btn-gmail {
  background: #d44638;
  margin-left: 1.25rem;
}

.btn.btn-gmail:hover {
  background: #da6155;
}

.btn.btn-warning {
  background: #FCAA3F;
}

.btn.is-round {
  border-radius: 50px;
}

@media (max-width: 1280px) {
  .btn {
    padding: .75rem 1.2rem;
    font-size: 1rem;
  }
}

.btn-buy {
  background: #10CC4E;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.btn-buy ion-icon {
  color: white;
  font-size: 1.5rem;
}

body.poker-lobby {
  background: radial-gradient(#171e37, #0e1221) !important;
}

body.poker-lobby .lobby-sidebar {
  width: 270px;
  height: 100vh;
  margin-top: 0;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

body.poker-lobby .lobby-sidebar .swiper-container {
  width: 100%;
  height: 500px;
}

body.poker-lobby .lobby-sidebar .stake-slider {
  position: relative;
}

body.poker-lobby .lobby-sidebar .stake-slider .swiper-button-up {
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  color: white;
  font-size: 24px;
  z-index: 1020;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 0;
  cursor: pointer;
  padding: 1rem;
}

body.poker-lobby .lobby-sidebar .stake-slider .swiper-button-up:hover {
  color: #d4916c;
}

body.poker-lobby .lobby-sidebar .stake-slider .swiper-button-down {
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  color: white;
  font-size: 24px;
  z-index: 1020;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  padding: 1rem;
}

body.poker-lobby .lobby-sidebar .stake-slider .swiper-button-down:hover {
  color: #d4916c;
}

body.poker-lobby .table-slider .swiper-wrapper {
  height: auto !important;
}

body.poker-lobby .table-slider .swiper-pagination {
  position: absolute;
  bottom: 180px;
}

body.poker-lobby .table-slider .swiper-pagination .swiper-pagination-bullet {
  background: white;
  margin: 0 10px;
  width: 10px;
  height: 10px;
}

body.poker-lobby .table-slider .swiper-scrollbar-drag {
  background: rgba(212, 145, 108, 0.68);
}

body.poker-lobby .lobby-tables {
  margin-top: -100px;
}

body.poker-lobby .lobby-tables .lobby-table {
  cursor: pointer;
}

body.poker-lobby .lobby-container {
  width: calc(100% - 300px);
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body.home {
  background-image: url("../../images/room-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.sidebar-nav {
  position: fixed;
  top: 0;
  color: white;
  margin-top: 0;
  height: 100vh;
  width: 270px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  background: #171e37;
  -webkit-box-shadow: 10px 0 30px rgba(0, 0, 0, 0.3);
          box-shadow: 10px 0 30px rgba(0, 0, 0, 0.3);
}

.sidebar-nav .game-brand {
  width: 100%;
  position: absolute;
  top: 6rem;
  text-align: center;
}

.sidebar-nav .game-brand .brand-poker {
  font-size: 3rem;
  color: white;
}

.sidebar-nav .nav-links {
  margin-top: 12rem;
  width: 100%;
}

.sidebar-nav .nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem !important;
  text-align: left;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 1.5rem 2rem;
  margin: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #171e37;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sidebar-nav .nav-links a:hover {
  background: #1f2849;
}

.sidebar-nav .nav-links a ion-icon {
  color: #cbab45;
  margin-right: 1.5rem;
  font-size: 1.5rem;
}

@media (max-width: 900px) {
  .sidebar-nav {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    z-index: 1020;
  }
  .sidebar-nav.is-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.room-container {
  width: calc(100% - 270px);
  padding: 0 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  position: absolute;
  float: right;
  right: 0;
}

.room-container .lobby-table {
  width: 80%;
}

.register-table {
  margin-top: -12rem;
}

.register-table .row {
  width: 100%;
}

.register-table .table-info {
  width: 100%;
  background: none;
}

.register-table .table-info span {
  font-size: 2em;
  margin-bottom: 3rem;
}

.register-table .table-info .room-block {
  text-align: center;
  margin: 0 2rem;
  width: 100%;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 3rem 1rem;
  -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.68);
          box-shadow: 0 20px 60px rgba(0, 0, 0, 0.68);
}

.register-table .table-info .room-block.private {
  background: #b22113;
  border: 1px solid #84180e;
}

.register-table .table-info .room-block.private:hover {
  background: #c92515;
}

.register-table .table-info .room-block.public {
  background: #09a2ac;
  border: 1px solid #06747c;
}

.register-table .table-info .room-block.public:hover {
  background: #0ab9c4;
}

.register-table .table-info .room-block h2 {
  font-size: 2rem;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}

.register-table .table-info .room-block span {
  font-weight: 400;
  text-shadow: none;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0;
}

.register-table .table-info .room-block span a {
  font-size: 1.25rem;
  opacity: 1;
  margin: 0 1rem;
  text-decoration: none;
  color: white;
  padding: .5rem 2rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.register-table .table-info .room-block span a:hover {
  background: white;
  color: black;
}

.register-table .table-info .coming-soon {
  margin-top: 3rem;
}

.space-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.space-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.text-bold {
  font-size: 700 !important;
}

.text-light {
  font-size: 300 !important;
}

@media screen and (min-width: 576px) and (max-width: 1280px) {
  .register-wrapper {
    width: 550px;
  }
  .register-wrapper .register-box {
    padding: 1rem 2rem;
  }
  .register-wrapper .register-box .reg-box-title span {
    font-size: 2rem;
    -webkit-text-stroke: 1.5px #b22113;
    height: 65px;
    width: 65px;
  }
  .register-wrapper .form .form-group .form-input {
    padding: .75rem 1.2rem;
  }
}

@media screen and (max-width: 575px) {
  .register-wrapper {
    width: 80%;
  }
  .register-wrapper .register-box {
    padding: 1rem 2rem;
  }
  .register-wrapper .register-box .reg-box-title span {
    font-size: 2rem;
    -webkit-text-stroke: 1.5px #b22113;
    height: 65px;
    width: 65px;
  }
  .register-wrapper .form .form-group .form-input {
    padding: .75rem 1.2rem;
  }
  .register-wrapper .form .form-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .btn.btn-gmail {
    background: #d44638;
    margin-left: 0;
    margin-top: 1rem;
  }
}

.poker-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1020;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.poker-modal .modal-dialog {
  position: relative;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out;
  width: 100%;
  max-width: 600px;
  margin: .5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.poker-modal .modal-dialog button.modal-close {
  position: absolute;
  right: -1rem;
  top: -1rem;
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  color: white;
  padding: 0;
  outline: none;
}

.poker-modal .modal-dialog button.modal-close ion-icon {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.poker-modal .modal-dialog button.modal-close:hover ion-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.poker-modal .modal-dialog .modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #171E37;
  background-clip: padding-box;
  border: 4px solid #FCAA3F;
  border-radius: 60px;
  outline: 0;
  padding: 4rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.poker-modal .modal-dialog .modal-content .modal-title {
  text-align: center;
  margin-bottom: 2rem;
}

.poker-modal .modal-dialog .modal-content .modal-title h1.modal-heading {
  font-size: 2rem;
  margin: 0;
}

.poker-modal .modal-dialog .modal-content .modal-title p.sub-heading {
  opacity: .5;
}

@media (min-width: 900px) {
  .poker-modal .modal-dialog {
    width: 520px;
    margin: 0 auto;
  }
}

.poker-modal::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}

.poker-modal .range-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 2rem;
}

.poker-modal .range-price #minPrice, .poker-modal .range-price #maxPrice {
  font-size: 1rem;
  color: #FFD093;
  font-weight: 300;
  text-align: center;
}

.poker-modal .range-price #minPrice .price, .poker-modal .range-price #maxPrice .price {
  font-size: 26px;
  margin-bottom: .5rem;
}

.poker-modal.show {
  visibility: visible;
  opacity: 1;
}

.poker-modal.show .modal-dialog {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.poker-header {
  position: absolute;
  z-index: 1020;
  width: 100%;
  top: 0;
  padding: 30px 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.poker-header .header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.poker-header .header-left .header-user {
  background: transparent;
  padding: 0;
  margin: 0 1rem;
}

.poker-header .header-left .header-user img {
  height: 32px;
  width: 32px;
  margin-right: 1rem;
}

.poker-header .header-left .header-user span {
  font-size: 1rem;
}

.poker-header .header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.poker-header .header-right .header-amount {
  background: transparent;
}

.poker-header .header-right .header-amount .amount-icon {
  width: 32px;
  height: 32px;
  margin: 0 1rem;
  left: 0;
}

.poker-header .header-right .header-amount span {
  font-size: 1rem;
}

@media (max-width: 900px) {
  .poker-header .header-left {
    margin-left: 66px;
  }
}

.burger-menu {
  position: absolute;
  display: none;
  left: 1rem;
  top: 1rem;
  width: 45px;
  height: 45px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.875rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 50%;
  z-index: 1060;
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background: rgba(23, 30, 55, 0.5);
}

.burger-menu.is-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

@media (max-width: 900px) {
  .burger-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.slidecontainer {
  width: 100%;
  margin-bottom: 2rem;
}

.slidecontainer .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #3C4871;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  padding: 0;
  border: none;
}

.slidecontainer .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 23px;
  height: 24px;
  border: 0;
  border-radius: 50%;
  background-color: #b22113;
  background-image: url('../../images/chip-range.png');
  cursor: pointer;
}

.slidecontainer .slider::-moz-range-thumb {
  width: 23px;
  height: 24px;
  border: 0;
  border-radius: 50%;
  background-color: #b22113;
  background-image: url('../../images/chip-range.png');
  cursor: pointer;
}

.inputPrice {
  position: relative;
}

.inputPrice .inputSign {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  font-size: 1.25rem;
  left: .875rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.inputPrice input {
  margin: 0;
  background: white;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  font-size: 1.25rem;
  padding: 1rem;
  padding-right: .25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: auto;
  max-width: 120px;
  border-radius: 4px;
  border: none;
  text-align: center;
  -webkit-box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.5);
}
/*# sourceMappingURL=enk.css.map */

.room-container {
  position: relative;
  padding-top: 7rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media (max-width: 900px) {
  .room-container {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 6rem;
  }
}

.lobby-table {
  margin-top: 0;
  width: 100% !important;
}

.lobby-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lobby-actions .btn-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}

.lobby-actions .btn-block .btn {
  padding: 1rem 2rem;
  margin-right: 1rem;
  white-space: nowrap;
}

.lobby-actions .btn-block .btn:last-child {
  margin-right: 0;
}

.lobby-actions .filter {
  background: black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  height: 100%;
}

.lobby-actions .filter .room-type-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.lobby-actions .filter .room-type-filter h3 {
  font-size: 1.125rem;
  margin: 0;
  margin-right: 1rem;
  color: #727681;
  font-weight: 400;
}

.lobby-actions .filter .room-type-filter p {
  margin: 0;
}

.lobby-actions .filter .room-type-filter [type="radio"]:checked,
.lobby-actions .filter .room-type-filter [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.lobby-actions .filter .room-type-filter [type="radio"]:checked + label,
.lobby-actions .filter .room-type-filter [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  margin-left: 1rem;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: white;
  font-size: 1rem;
}

.lobby-actions .filter .room-type-filter [type="radio"]:checked + label:before,
.lobby-actions .filter .room-type-filter [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.lobby-actions .filter .room-type-filter [type="radio"]:checked + label:after,
.lobby-actions .filter .room-type-filter [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #2e55e4;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.lobby-actions .filter .room-type-filter [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.lobby-actions .filter .room-type-filter [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 900px) {
  .lobby-actions .btn-block .btn {
    padding: .5rem 2rem;
    margin-right: 1rem;
  }
  .lobby-actions .filter {
    padding: .7rem 1rem;
  }
}

.lobby-room-lists {
  width: 100%;
}

.lobby-room-lists table.room-lists {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 8px;
  position: relative;
  border: 1px solid #35363b;
  border-radius: 8px;
  table-layout: fixed;
}

.lobby-room-lists table.room-lists thead {
  background: black;
}

.lobby-room-lists table.room-lists thead tr {
  display: block;
}

.lobby-room-lists table.room-lists thead th {
  text-align: left;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  overflow: hidden;
  padding: 2rem 1rem;
  word-break: normal;
  border-bottom: 4px solid #35363b;
  width: 200px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.lobby-room-lists table.room-lists tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 540px;
  background: rgba(0, 0,0,.5);
}

.lobby-room-lists table.room-lists tbody tr {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  display: block;
}

.lobby-room-lists table.room-lists tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.9);
}

.lobby-room-lists table.room-lists tbody tr:hover {
  background: rgba(255, 255, 255, 0.1);
}

.lobby-room-lists table.room-lists tbody tr:hover .text-muted {
  opacity: 1;
}

.lobby-room-lists table.room-lists tbody td {
  border-color: black;
  border-style: solid;
  border-width: 0;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  overflow: hidden;
  padding: 1rem 1rem;
  width: 200px;
  word-break: normal;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (max-width: 900px) {
  .lobby-room-lists table.room-lists thead th {
    padding: .5rem 1rem;
  }
  .lobby-room-lists table.room-lists tbody {
    max-height: 350px;
  }
  .lobby-room-lists table.room-lists tbody td {
    padding: .5rem 1rem;
  }
}

.live-table {
  display: block;
}

@media (max-width: 900px) {
  .live-table {
    display: none;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-muted {
  opacity: .5;
}

.section-header {
  padding: 1.2rem 0;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.section-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300;
}

.section-header h2 span {
  background: #b22113;
  color: white;
  font-weight: 700;
  padding: .25rem .875rem;
  border-radius: 4px;
  margin-right: .1rem;
}

.stream-table {
  height: 280px;
  background: #0d1222;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  border: 3px solid rgba(203, 171, 69, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  pointer-events: none;
}

.stream-table .player-seats {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.stream-table .player-seats .player {
  position: absolute;
  width: 50px;
  height: 45px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 50%;
  -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

.stream-table .player-seats .player .name {
  position: absolute;
  margin: 0 auto;
  top: -1.2rem;
  font-size: .875rem;
  margin-bottom: .2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

.stream-table .player-seats .player .player-money {
  position: absolute;
  top: 100%;
  font-size: .675rem;
  margin-top: 1rem;
}

.stream-table .player-seats .player .profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid rgba(84, 163, 176, 0.6);
  background: rgba(0, 0, 0, 0.8);
}

.stream-table .player-seats .player:nth-child(1) {
  top: 10%;
  left: 20%;
}

.stream-table .player-seats .player:nth-child(1) .hand-cards {
  top: 40%;
  left: 50px;
}

.stream-table .player-seats .player:nth-child(2) {
  top: 10%;
  right: 20%;
}

.stream-table .player-seats .player:nth-child(2) .hand-cards {
  top: 40%;
  left: -20px;
}

.stream-table .player-seats .player:nth-child(3) {
  top: 55%;
  left: 15%;
}

.stream-table .player-seats .player:nth-child(3) .hand-cards {
  top: 40%;
  left: 50px;
}

.stream-table .player-seats .player:nth-child(4) {
  top: 55%;
  right: 15%;
}

.stream-table .player-seats .player:nth-child(4) .hand-cards {
  top: 40%;
  left: -20px;
}

.stream-table .player-seats .player:nth-child(5) {
  top: 65%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.stream-table .player-seats .player:nth-child(5) .hand-cards {
  top: 70%;
  left: 15px;
}

.stream-table .player-seats .player .hand-cards {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 22px;
}

.stream-table .player-seats .player .hand-cards .hand-card {
  position: absolute;
}

.stream-table .player-seats .player .hand-cards .hand-card:nth-child(1) {
  left: -7px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.stream-table .player-seats .player .hand-cards .hand-card:nth-child(2) {
  left: 7px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.stream-table .player-seats .player::after {
  content: '';
  width: 50%;
  height: 50%;
  background-image: url("../../images/icomoon-free_spades.png");
  background-size: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -40%);
          transform: translate(-50%, -40%);
  opacity: .2;
}

.stream-table .table-cards {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  top: 0;
}

.stream-table .table-cards .table-card {
  position: absolute;
  margin-top: -30px;
  width: 30px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.stream-table .table-cards .table-card:nth-child(1) {
  left: calc(50% - 60px);
}

.stream-table .table-cards .table-card:nth-child(2) {
  left: calc(50% - 30px);
}

.stream-table .table-cards .table-card:nth-child(3) {
  left: calc(50%);
}

.stream-table .table-cards .table-card:nth-child(4) {
  left: calc(50% + 30px);
}

.stream-table .table-cards .table-card:nth-child(5) {
  left: calc(50% + 60px);
}

.stream-table .poker-table {
  pointer-events: none;
  width: 100%;
}

.stream-table .poker-table img {
  pointer-events: none;
}
/*# sourceMappingURL=lobby.css.map */