@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  background:#0e1323;
  font-family: 'Roboto', sans-serif;
  color:#FFF;
  overflow-x:hidden;
}


input {
  background:transparent;
  border:none;
  border-bottom:4px solid #151c2f;
  border-radius:0;
  padding:15px 0;
  width:100%;
  display:inline-block;
  color:#FFF;
  margin-bottom:15px;
  box-sizing:border-box;
}

button {
  border:none;
  border-radius:40px;
  padding:15px;
  width:100%;
  display:inline-block;
  box-sizing:border-box;
  background: #10cc4e;
  font-size:24px;
  font-weight:bold;
}

input:focus {
  outline:none;
}

.poker {
  width:100vw;
  max-width:1334px;
  height:100vh;
  background:url('/images/table.png') no-repeat top center;

  margin:0 auto;
  position:relative;
}

.poker .seat {
  position:absolute;
  font-size:19px;
  color:#FFF;
}

.poker .seat svg {
  position:absolute;
  z-index:3;
  stroke-dashoffset: 0;
  stroke-dasharray: 500;
  transform:rotate(-90deg);  
}

svg.timer {
  animation-name: circle;
  animation-duration: 10s;
  animation-timing-function: linear;
}

.poker .seat > img, .poker .seat video {
  width:130px;
  height:auto;
  max-width:400px;
  min-width:70px;
  border-radius:50%;
  border:4px solid rgba(84,163,176,.6);
  z-index:2;
  position:relative;
}

.poker .seat > img.timer-img {
  animation-name: border;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.player1 {
  top:8%;
  left:18%;
}

.player2 {
  top:8%;
  right:16%;
}

.player3 {
  top:43%;
  right:10.5%;
}

.player4 {
  top:50%;
  left:45%;
}

.main > img, .main > svg {
  transform: scale(1.3);
}

.main > svg {
  transform: scale(1.3) rotate(-90deg) !important;
}

.player5 {
  top:43%;
  left:11.5%;
}

.player-name {
  position:absolute;
  top:-30px;
  width:100%;
  text-align:center;
}

.balance {
  width:100%;
  text-align:center;
  font-size:22px;
}

.player1 .balance {
  top:1vh;
  position:absolute;
}

.player2 .balance {
  top:1vh;
  left:-100%;
  position:absolute;
}

.player3 .balance {
  position: absolute;
  bottom:-32px;
  left:0;
  width:100%;
}

.player5 .balance {
  position: absolute;
  bottom:-32px;
  left:0;
  width:100%;
}

.main .balance {
  width:100%;
  position:absolute;
  bottom:-80px;
  left:0;
  padding:6px;
  background:rgba(0,0,0,.9);
  border-radius:20px;
  z-index:6;
}

.badge {
  width:35px;
  height:35px;
  position:absolute;
  border-radius:50%;
  text-align:center;
  padding-top:10px;
  box-sizing: border-box;
  font-size:14px;
  font-weight:bold;
  transition: all 0.5s ease;
  z-index:4;
}

.badge.sb {
  background:#fcaa3f;
  color:#4e3a24;
}

.badge.bb {
  background:#c93c3c;
  color:#FFF;
}

.badge.dl {
  background:#2b3453;
  color:#FFF;
}

.player1-badge, .player1-badge-initial {
  top:19%;
  left:18%;
}

.player2-badge, .player2-badge-initial {
  top:19%;
  left:81%;
}

.player3-badge, .player3-badge-initial {
  top:54%;
  left:86%;
}

.player4-badge, .player4-badge-initial {
  top:58%;
  left:42.5%;
}

.player5-badge, .player5-badge-initial {
  top:54%;
  left:11%;
}

.seat:not(.main) .cards, .seat:not(.main) .user-cards-main {
  position:absolute;
  right:-40px;
  bottom:0px;
  width:40px;
  height:70px;
} 

.seat:not(.main) .cards img, .seat:not(.main) .user-cards-main img {
  position:absolute;
  width:40px;
  left:0;
} 

.seat:not(.main) .cards img:last-child {
  transform:rotate(25deg);
  left:15px !important;
}

.player2 .cards, .player2 .user-cards-main {
  left:-80px;
}

.player3 .cards, .player3 .user-cards-main {
  left:-80px;
}

.main .cards, .main .user-cards-main {
  position:absolute;
  width:100%;
  left:0;
  z-index:5;
}

.main .cards img, .main .user-cards-main img {
  width:80px;
  position:absolute;
  margin-top:-40px;
}

.main .cards img:first-child, .main .user-cards-main img:first-child {
  left:0;
  transform: rotate(-20deg);
}
.main .cards img:last-child, .main .user-cards-main img:last-child {
  right:0;
  transform: rotate(10deg);
}

.action {
  position:absolute;
  display:flex;
  align-items: center;
  font-weight:800;
  font-size:22px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  z-index:1;
}

.action img {
  width:35px;
  margin-right:10px;
}

.main .action {
  left:-120px;
  top:20px;
}

.player5 .action {
  top:30px;
  right:-110px;
}

.player1 .action {
  bottom:-25px;
  right:-80px;
}

.player2 .action {
  bottom:-25px;
  left:-80px;
}

.player3 .action {
  top:30px;
  left:-110px;
}

.action span {
  overflow:hidden;
}

.buttons {
  width:100%;
  position:absolute;
  bottom:0;
  overflow:hidden;
  padding-top:20px;
}

.buttons div {
  width:40%;
  position:relative;
  margin-bottom:50px;
}

.buttons-left {
  float:left;
}

.buttons-right {
  float:right;
}

.buttons div a {
  border-radius:60px;
  font-size:20px;
  text-align:center;
  width:39%;
  margin:5%;
  display:inline-block;
  color:#000;
  padding:20px;
  box-sizing: border-box;
  text-decoration:none;
  font-weight:800;
  position:relative;
  bottom:0;
}

.button-fold {
  background:#e84545;
}

.button-check {
  border:3px solid rgba(255,255,255,.5);
  color:#FFF !important;
}

.button-call {
  background:#10cc4e;
}

.button-bet {
  background:#fcaa3f;
}

.table-amount-container {
  left:50%;
  position:absolute;
  top:25%;
}

.table-cards-container {
  left:50%;
  position:absolute;
  top:29%;
}

.table-amount {
  background:rgba(0,0,0,.6);
  display:inline-flex;
  align-items: center;
  border-radius:25px;
  padding-right:20px;
  font-size:22px;
  position:relative;
  left:-50%;
  opacity:1;
}

.table-amount img {
  width:45px;
  position:relative;
  left:-15px;
}

.table-cards {
  position:relative;
  left:-50%;
}

.main-card {
  width:85px;
  display:inline-block;
  box-sizing:border-box;
}

.main-card img {
  position:absolute;
  width:90px;
  height:129px;
}

.table-amount span {
  width:auto;
  overflow:hidden;
}

.result {
  position:absolute;
  z-index:7;
  background:rgba(0,0,0,.8);
  top:-30px;
  padding:5px;
  width:100%;
  left:0;
  text-align:center;
  margin-left:-5px;
  font-size:24px;
  border-radius:25px;
  overflow:hidden;
  white-space: nowrap;
}

.card-hidden {
  visibility: hidden;
  transform: scaleX(0);
  height:129px;
}

.bet {
  display:flex;
  width:250px;
  height:70vh;
  position:absolute;
  top:0;
  right:0;
  padding:50px 0;
}

.bet .col-left {
  width:150px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  color:#FFF;
  text-decoration: none;
  float:left;
}

.bet .col-left a {
  color:#FFF;
  font-size:30px;
  text-decoration:none;
  text-align:center;
  font-weight:bold;
}

.bet .col-left input {
  background:#080a10;
  width:auto;
  display:inline;
  padding:8px;
  border-radius:25px;
  font-size:28px;
  color:#FFF;
  border:none;
  text-align:center;
  font-weight:bold;
  box-sizing:border-box;
}

.bet .col-right {
  float:right;
  position:relative;
  width:100px;
  text-align:center;
}

.progress-bar {
  position:relative;
  background:#080a10;
  height:100%;
  width:6px;
  margin:0 auto;
}

/* 
.progress {
  width:6px;
  height:20%;
  position:absolute;
  bottom:0;
  background:#10cc4e;
}

.progress:after {
  width:20px;
  height:20px;
  background:#10cc4e;
  position:absolute;
  content:' ';
  display:block;
  border-radius:50%;
  margin-left:-7px;
  bottom:-5px;
}

.progress:before {
  width:27px;
  height:27px;
  background:#10cc4e;
  position:absolute;
  content:' ';
  display:block;
  border-radius:50%;
  margin-left:-10px;
  top:-5px;
} */

.bet-plus, .bet-minus {
  font-size:72px !important;
}

.user-cards-main {
  visibility:visible;
} 

.user-cards-main img:first-child {
  transform:scaleX(0);
}

.user-cards-main img:last-child {
  transform:scaleX(0);
  left:15px;
}

.final-result {
  height:150px;
  background:#070912;
  position:absolute;
  width:100%;
  top:30%;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:48px;
  font-weight:bold;
  z-index:1;
}

.user-final-result {
  width:100%;
  position:absolute;
  text-align:center;
  top:-25px;
  z-index:10;
  padding:10px;
  border-radius:30px;
  font-size:24px;
  font-weight:bold;
  left:0;
  box-sizing:border-box;
}

.user-winner {
  background:#ffac29;
  color:#000;
}

.user-lose {
  background:#ee3636;
  color:#FFF;  
}

.blocker {
  z-index:11;
}

.modal {
  background:#080a10;
  box-sizing: border-box;
}

.modal h2 {
  font-size:40px;
  text-align:center;
}

.modal input {
  background:transparent;
  border:none;
  border-bottom:4px solid #32343b;
  border-radius:0;
  padding:15px 0;
  width:100%;
  display:inline-block;
  color:#FFF;
  margin-bottom:15px;
  box-sizing:border-box;
}

.modal button {
  border:none;
  border-radius:40px;
  padding:15px;
  width:100%;
  display:inline-block;
  box-sizing:border-box;
  background: #10cc4e;
  font-size:24px;
  font-weight:bold;
}

.user-video {
  border-radius:50%;
  overflow:hidden
} 

.poker .seat video {
  height:130px !important;
  width:130px !important;
}

.header {
  position: absolute;
  width: 100%;
  display:flex;
  top: 50px;
  justify-content: space-between;
}

.header-user {
  float:left;
  padding:0 45px;
  background:#171c2e;
  border-radius:0 50px 50px 0;
  justify-content: center;
  display:flex;
  align-items: center;
}

.header-user img {
  width:60px;
  border-radius:50%;
  float:left;
  margin-right:30px;
  position:relative;
  transform: scale(1.3);
}

.header-user span, .header-amount span {
  font-size:18px;
  color:#fbdf6e;
  font-weight:800;
}

.header-amount {
  background:#171c2e;
  padding:0 45px 0 0;
  justify-content: center;
  display:flex;
  align-items: center;
  border-radius:50px 0 0 50px;
}

.header-amount span {
  margin-right:20px;
}

.header-amount > img {
  transform: scale(1);
  position:relative;
  left:-20px;
}

.lobby-table {
  width:33.3333%;
  float:left;
  align-items:center;
  margin-top:30px;
}

.table-info {
  width:100%;
  background:url('/images/lobby-table.png') no-repeat center center / contain;
  display:flex;
  flex-direction:column;
  height:200px;
  padding:50px 30px;
  align-items: center;
  justify-content: space-evenly;
  box-sizing:border-box
}

.table-info span {
  font-size:18px;
  color:#FFF;
  font-weight:900;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}

.table-info span:last-child {
  font-size:26px;
  display:flex;
  justify-content: center;
  line-height: 27px;
}

.table-info span:last-child img {
  margin-right:10px;
}

.table-info span:last-child img:last-child {
  margin-left:30px;
}

.table-user {
  display:flex;
  flex-direction:column;
  align-items: center;
}

.lobby-sidebar {
  width:20%;
  float:left;
  margin-top:60px;
  align-items: center;
  justify-content: center;

}

.lobby-container {
  width:80%;
  float:left;
}

.swiper-container {
  width: 100%;
  height: 500px;
}

.table-user img {
  width:50px;
  height:auto;
  border-radius:50%;
  margin-top:-50px;
  margin-bottom:10px;
  border:3px solid #408981
}

.table-user span {
  opacity:.7;
}

.stake-slider .swiper-slide {
  opacity:.2;
  font-size:20px;
  text-align:center;
}

.stake-slider .swiper-slide-prev, .stake-slider .swiper-slide-next {
  opacity:.75;
  font-size:24px;
}

.stake-slider .swiper-slide-active {
  font-size:36px;
  opacity:1;
  color:#d4916c;
}

.lobby {
  display:flex;
  min-height:calc(100vh - 120px);
}

.lobby-buttons-row {
  display:block;
  width:100%;
  text-align:center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.create-room {
  width:351px;
  height:75px;
  display:inline-block;
  text-align:center;
  color:#201104;
  font-weight:900;
  text-decoration:none;
  background:url('/images/create-room.png') no-repeat;
  font-size:30px;
  padding-top:20px;
  margin:40px 20px 0;
}

.play-now {
  width:351px;
  height:75px;
  display:inline-block;
  text-align:center;
  color:#201104;
  font-weight:900;
  text-decoration:none;
  background:url('/images/play-now.png') no-repeat;
  font-size:30px;
  padding-top:20px;
  margin:40px 20px 0;
}

.create-room-form {
  text-align:center;
}

.password-field input{
  width:30px;
  display:inline-block;
  margin-left:20px;
  text-align:center;
}

.irs--round .irs-handle {
  top: 30px !important;
  width: 16px !important;
  height: 16px !important;
  border: 4px solid #10cd4f !important;
  background-color: #10cd4f !important;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0,0,255,0.3);
}

.irs--round .irs-bar {
  background-color: #19223f !important;
}

.irs--round .irs-line {
  background-color: #ffffff !important;
}

.irs--round .irs-single {
  background-color: #10cd4f !important;
}

.irs--round .irs-single:before {
  border-top-color: #10cd4f !important;
}

.simple-switch-outter.unchecked {
  box-shadow: #111629 0 0 0 inset !important;
  border-color: #111629 !important;
  background-color: #111629 !important;
}

.simple-switch-outter .simple-switch-circle {
  background:url('/images/switch.png') no-repeat !important;
  width:39px !important;
  height:39px !important;
  top: -1px !important;
}

.simple-switch-outter {
  height:36px !important;
  width:58px !important;
  float:right;
}

.simple-switch-outter.checked {
  border-color: #111629 !important;
  box-shadow: #111629 0 0 0 16px inset !important;
  background-color: #111629 !important;
}

.password-field {
  margin-top:10px;
  margin-bottom:10px;
}

.password-field span {
  font-size:20px;
  line-height:18px;
}

.password-field input {
  height:20px;
}

.create-room-modal-button {
    width: 351px !important;
    height: 75px !important;
    text-align: center;
    color: #201104;
    font-weight: 900;
    text-decoration: none;
    background: url('/images/play-now.png') no-repeat !important;
    font-size: 30px;
    padding-top: 20px;
    margin: 30px auto 20px !important;
    display:flex !important;
    justify-content:center;
    align-content:center;
    line-height: 43px !important;
    outline:none !important;
}

.irs {
  margin:10px 0 30px !important;
}

.modal p {
  line-height:26px;
  color:#ccd0de;
}

.register {
  width:100%;
  min-height:100vh;
  background:url('/images/register-bg.jpg') no-repeat center center / cover;
  text-align:center;
}

.register-wrapper {
  width:900px;
  margin:5% auto;
}

.register-wrapper p {
  font-size:20px;
  line-height:24px;
}

.login-buttons-row {
  margin-bottom:30px;
}

.login-buttons-row a {
  margin:0 20px;
}

.register input {
  border-color:rgba(255,255,255,.5);
  color:#FFF;
  max-width:528px;
  margin:0 auto;
  display:block;
  font-size:18px;
}

.register button {
  max-width:528px;
  margin:0 auto;
  display:block;
  /* margin-top:30px; */
}

.register-sidebar {
  text-align:left;
  padding:0 60px;
}

.register-sidebar a {
  color:#FFF;
  text-decoration: none;
  display:block;
  font-size:36px;
  margin:25px 0;
  font-weight:900;
}

.register-sidebar a:first-child {
  margin-top:0;
}

.register-table .table-info {
  width:902px;
  height:443px;
  background:url('/images/register-table.png')
}

.register-table p {
  color: #FFF;
  font-weight: 900;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  font-size:26px;
  text-align:center;
}

.register-table .row {
  width:600px;
}

.register-table .row p, .register-table .row > a, .register-table .row form {
  width:50%;
}

.register-table p {
  margin:15px 0;
}

.register-table .row > a {
  text-decoration: none;
  display:flex;
  text-align:center;
  flex-direction: column;
  font-weight: 900;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  font-size: 26px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color:#FFF;
}

.register-table p span, .register-table p a, .register-table a span {
  font-size:16px !important;
  color:#FFF;
  line-height:27px;
}

.register-table .row {
  display:flex;
  flex-direction: row;
}

.register-table p span a {
  margin:0 5px;
}

.register-table .table-info {
  justify-content: flex-start;
}

.register-table .table-info > span {
  font-size:36px;
  margin-top:55px;
  margin-bottom:20px;
}

.register-table .table-info form input {
  border-bottom:3px solid rgba(0,0,0,.3);
  height: 40px;
  margin-bottom:5px;
}

.register-table .table-info form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFF;
  opacity: .8; /* Firefox */
}

.register-table .table-info form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFF;
  opacity: .8;
}

.register-table .table-info form input::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFF;
  opacity: .8;
}

/*
.register-table .table-info form button {
  background:transparent;
  color:#FFF;
  font-size:24px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  cursor:pointer;
} */

.register-table .table-info form button:focus {
  border:none;
  outline:0;
}

.register4 .row > p {
  margin-top:10px;
}

@keyframes circle {
  from {
    stroke-dashoffset:0;}
  to {
    stroke-dashoffset:-500;
  }
}

@keyframes border {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    -moz-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
  }
  50% {
    -webkit-box-shadow: 0px 0px 0px 8px rgba(50,94,111,0.75);
    -moz-box-shadow: 0px 0px 0px 8px rgba(50,94,111,0.75);
    box-shadow: 0px 0px 0px 8px rgba(50,94,111,0.75);
  }
  100% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    -moz-box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
    box-shadow: 0px 0px 0px 0px rgba(50,94,111,0.25);
  }
}

.spinner {
  margin: 0px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #00A4BA;
  height: 100%;
  width: 6px;
  margin-left: 1px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}