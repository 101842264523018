/* .counterDiv{
    background: rgb(63, 63, 63);
    color: aliceblue;
} */
h1{
    text-align: center;
    font-size: 3em;
}
h3{
    font-size: 2em;
    text-align: center;
}
.totalPlayersCounterContainer .counter{
    position: relative;
    left: 30vw;
    right: 30vw;
    width: 40vw;
    height: 60vh;
    text-align: center;
}
.totalPlayersCounterContainer .counter span{
    color: chartreuse;
    font-size: 30vw;
    margin-top: 0;
    line-height: 0.7;
}
.viewPlayersContainer{
    text-align: center;
}
.viewPlayersContainer a{
    /* background-color: #4CAF50; Green */
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    background-color: #4CAF50;
 
    color: white;
    border: 2px solid #4CAF50;
}
.viewPlayersContainer a:hover{
    background-color: white; 
    color: black; 
    
}